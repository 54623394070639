/**Project based on the Minimizable web chat UI template https://github.com/microsoft/BotFramework-WebChat/tree/master/samples/06.recomposing-ui/a.minimizable-web-chat
 * 
 * This component defines a ReactWebChat from the BotFramework package.
 * It will be mounted on top of the MinimizableWebChat component, making this the leaf node/component 
 * 
 * It only gets rendered if the user "expands" the chat bubble.
 * 
 * The component will display a spinner with a bot image instead of the chat if there is no directline/token
 */

import React, { useEffect, useMemo } from 'react';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';

import '../assets/css/WebChat.css';

//Define component with inherited props from the parent: MinimizableWebChat
const WebChat = ({ className, onFetchToken, store, styleOptions, token, chatLanguage }) => {
    //Creates the connection with Copilot Studio once the token has been fetched.
    //The useMemo hook helps to trigger this code again when the token has changed
    const directLine = useMemo(() => token ? createDirectLine({ token }) : null, [token]);
    //const borderStyle = 'solid 1px #3B3938';
    //redefines the styleOptions prop to overwrite some defaults.
    //For further styling please check the styleOptions.ts file in https://github.com/microsoft/BotFramework-WebChat/blob/master/packages/api/src/StyleOptions.ts
    //the botAvatarImage attribute was not picking up the images inside the solution so it points to an URL
    /**
     * Background color: 
     * regular: #EFEFEF
     * 10% darker: #d7d7d7
     * 20% darker: #bfbfbf
     * 
     * charcoal: #50534C
     * 
     * bot bubble color: 
     * regular: #2ecddc40
     * 
     * bot accent color:
     * regular: #FF8204
     */
    styleOptions = {
        accent: '#383937',
        //botAvatarBackgroundColor: '#E14F3D',
        //botAvatarImage: 'https://github.com/andres-laverde/starholder/blob/main/oc_secondary_icon_white_50.png?raw=true',
        //botAvatarInitials: '',
        hideUploadButton: true,
        primaryFont: 'Poppins',
        //bubbleBorderColor: '#E7E7E8',
        bubbleBackground: '#f0d3dc',
        backgroundColor: '#efefef',
      
    };

    //When teh component mounst it fetches the token
    useEffect(() => {
        onFetchToken();
    }, [onFetchToken]);


    //Override default sendbox placeholder
    const overrideLocalizedStrings = {
        TEXT_INPUT_PLACEHOLDER: 'Input your message'
    };

    return token ? (
        <ReactWebChat
            className={`${className || ''} web-chat`}
            directLine={directLine}
            store={store}
            styleOptions={styleOptions}
            locale={chatLanguage}
            overrideLocalizedStrings={overrideLocalizedStrings}
        />
    ) : (
        <div className={`${className || ''} connect-spinner`}>
            <div className="content">
                <div className="icon">
                    <span className="ms-Icon ms-Icon--Robot" />
                </div>
                <p>Please wait while we are connecting.</p>
            </div>
        </div>
    );
};

export default WebChat;
