var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = {
    exports: {}
  }).exports, mod), mod.exports;
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from)) if (!__hasOwnProp.call(to, key) && key !== except) __defProp(to, key, {
      get: () => from[key],
      enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable
    });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", {
  value: mod,
  enumerable: true
}) : target, mod));

// ../markdown-it-attrs/utils.js
var require_utils = __commonJS({
  "../markdown-it-attrs/utils.js"(exports) {
    exports.getAttrs = function (str, start, options) {
      const allowedKeyChars = /[^\t\n\f />"'=]/;
      const pairSeparator = " ";
      const keySeparator = "=";
      const classChar = ".";
      const idChar = "#";
      const attrs = [];
      let key = "";
      let value = "";
      let parsingKey = true;
      let valueInsideQuotes = false;
      for (let i = start + options.leftDelimiter.length; i < str.length; i++) {
        if (str.slice(i, i + options.rightDelimiter.length) === options.rightDelimiter) {
          if (key !== "") {
            attrs.push([key, value]);
          }
          break;
        }
        const char_ = str.charAt(i);
        if (char_ === keySeparator && parsingKey) {
          parsingKey = false;
          continue;
        }
        if (char_ === classChar && key === "") {
          if (str.charAt(i + 1) === classChar) {
            key = "css-module";
            i += 1;
          } else {
            key = "class";
          }
          parsingKey = false;
          continue;
        }
        if (char_ === idChar && key === "") {
          key = "id";
          parsingKey = false;
          continue;
        }
        if (char_ === '"' && value === "" && !valueInsideQuotes) {
          valueInsideQuotes = true;
          continue;
        }
        if (char_ === '"' && valueInsideQuotes) {
          valueInsideQuotes = false;
          continue;
        }
        if (char_ === pairSeparator && !valueInsideQuotes) {
          if (key === "") {
            continue;
          }
          attrs.push([key, value]);
          key = "";
          value = "";
          parsingKey = true;
          continue;
        }
        if (parsingKey && char_.search(allowedKeyChars) === -1) {
          continue;
        }
        if (parsingKey) {
          key += char_;
          continue;
        }
        value += char_;
      }
      if (options.allowedAttributes && options.allowedAttributes.length) {
        const allowedAttributes = options.allowedAttributes;
        return attrs.filter(function (attrPair) {
          const attr = attrPair[0];
          function isAllowedAttribute(allowedAttribute) {
            return attr === allowedAttribute || allowedAttribute instanceof RegExp && allowedAttribute.test(attr);
          }
          return allowedAttributes.some(isAllowedAttribute);
        });
      }
      return attrs;
    };
    exports.addAttrs = function (attrs, token) {
      for (let j = 0, l = attrs.length; j < l; ++j) {
        const key = attrs[j][0];
        if (key === "class") {
          token.attrJoin("class", attrs[j][1]);
        } else if (key === "css-module") {
          token.attrJoin("css-module", attrs[j][1]);
        } else {
          token.attrPush(attrs[j]);
        }
      }
      return token;
    };
    exports.hasDelimiters = function (where, options) {
      if (!where) {
        throw new Error('Parameter `where` not passed. Should be "start", "end" or "only".');
      }
      return function (str) {
        const minCurlyLength = options.leftDelimiter.length + 1 + options.rightDelimiter.length;
        if (!str || typeof str !== "string" || str.length < minCurlyLength) {
          return false;
        }
        function validCurlyLength(curly) {
          const isClass = curly.charAt(options.leftDelimiter.length) === ".";
          const isId = curly.charAt(options.leftDelimiter.length) === "#";
          return isClass || isId ? curly.length >= minCurlyLength + 1 : curly.length >= minCurlyLength;
        }
        let start, end, slice, nextChar;
        const rightDelimiterMinimumShift = minCurlyLength - options.rightDelimiter.length;
        switch (where) {
          case "start":
            slice = str.slice(0, options.leftDelimiter.length);
            start = slice === options.leftDelimiter ? 0 : -1;
            end = start === -1 ? -1 : str.indexOf(options.rightDelimiter, rightDelimiterMinimumShift);
            nextChar = str.charAt(end + options.rightDelimiter.length);
            if (nextChar && options.rightDelimiter.indexOf(nextChar) !== -1) {
              end = -1;
            }
            break;
          case "end":
            start = str.lastIndexOf(options.leftDelimiter);
            end = start === -1 ? -1 : str.indexOf(options.rightDelimiter, start + rightDelimiterMinimumShift);
            end = end === str.length - options.rightDelimiter.length ? end : -1;
            break;
          case "only":
            slice = str.slice(0, options.leftDelimiter.length);
            start = slice === options.leftDelimiter ? 0 : -1;
            slice = str.slice(str.length - options.rightDelimiter.length);
            end = slice === options.rightDelimiter ? str.length - options.rightDelimiter.length : -1;
            break;
          default:
            throw new Error(`Unexpected case ${where}, expected 'start', 'end' or 'only'`);
        }
        return start !== -1 && end !== -1 && validCurlyLength(str.substring(start, end + options.rightDelimiter.length));
      };
    };
    exports.removeDelimiter = function (str, options) {
      const start = escapeRegExp(options.leftDelimiter);
      const end = escapeRegExp(options.rightDelimiter);
      const curly = new RegExp("[ \\n]?" + start + "[^" + start + end + "]+" + end + "$");
      const pos = str.search(curly);
      return pos !== -1 ? str.slice(0, pos) : str;
    };
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    exports.escapeRegExp = escapeRegExp;
    exports.getMatchingOpeningToken = function (tokens, i) {
      if (tokens[i].type === "softbreak") {
        return false;
      }
      if (tokens[i].nesting === 0) {
        return tokens[i];
      }
      const level = tokens[i].level;
      const type = tokens[i].type.replace("_close", "_open");
      for (; i >= 0; --i) {
        if (tokens[i].type === type && tokens[i].level === level) {
          return tokens[i];
        }
      }
      return false;
    };
    var HTML_ESCAPE_TEST_RE = /[&<>"]/;
    var HTML_ESCAPE_REPLACE_RE = /[&<>"]/g;
    var HTML_REPLACEMENTS = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;"
    };
    function replaceUnsafeChar(ch) {
      return HTML_REPLACEMENTS[ch];
    }
    exports.escapeHtml = function (str) {
      if (HTML_ESCAPE_TEST_RE.test(str)) {
        return str.replace(HTML_ESCAPE_REPLACE_RE, replaceUnsafeChar);
      }
      return str;
    };
  }
});

// ../markdown-it-attrs/patterns.js
var require_patterns = __commonJS({
  "../markdown-it-attrs/patterns.js"(exports, module) {
    "use strict";

    var utils = require_utils();
    module.exports = options => {
      const __hr = new RegExp("^ {0,3}[-*_]{3,} ?" + utils.escapeRegExp(options.leftDelimiter) + "[^" + utils.escapeRegExp(options.rightDelimiter) + "]");
      return [{
        name: "fenced code blocks",
        tests: [{
          shift: 0,
          block: true,
          info: utils.hasDelimiters("end", options)
        }],
        transform: (tokens, i) => {
          const token = tokens[i];
          const start = token.info.lastIndexOf(options.leftDelimiter);
          const attrs = utils.getAttrs(token.info, start, options);
          utils.addAttrs(attrs, token);
          token.info = utils.removeDelimiter(token.info, options);
        }
      }, {
        name: "inline nesting 0",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            shift: -1,
            type: str => str === "image" || str === "code_inline"
          }, {
            shift: 0,
            type: "text",
            content: utils.hasDelimiters("start", options)
          }]
        }],
        transform: (tokens, i, j) => {
          const token = tokens[i].children[j];
          const endChar = token.content.indexOf(options.rightDelimiter);
          const attrToken = tokens[i].children[j - 1];
          const attrs = utils.getAttrs(token.content, 0, options);
          utils.addAttrs(attrs, attrToken);
          if (token.content.length === endChar + options.rightDelimiter.length) {
            tokens[i].children.splice(j, 1);
          } else {
            token.content = token.content.slice(endChar + options.rightDelimiter.length);
          }
        }
      }, {
        name: "tables",
        tests: [{
          shift: 0,
          type: "table_close"
        }, {
          shift: 1,
          type: "paragraph_open"
        }, {
          shift: 2,
          type: "inline",
          content: utils.hasDelimiters("only", options)
        }],
        transform: (tokens, i) => {
          const token = tokens[i + 2];
          const tableOpen = utils.getMatchingOpeningToken(tokens, i);
          const attrs = utils.getAttrs(token.content, 0, options);
          utils.addAttrs(attrs, tableOpen);
          tokens.splice(i + 1, 3);
        }
      }, {
        name: "inline attributes",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            shift: -1,
            nesting: -1
          }, {
            shift: 0,
            type: "text",
            content: utils.hasDelimiters("start", options)
          }]
        }],
        transform: (tokens, i, j) => {
          const token = tokens[i].children[j];
          const content = token.content;
          const attrs = utils.getAttrs(content, 0, options);
          const openingToken = utils.getMatchingOpeningToken(tokens[i].children, j - 1);
          utils.addAttrs(attrs, openingToken);
          token.content = content.slice(content.indexOf(options.rightDelimiter) + options.rightDelimiter.length);
        }
      }, {
        name: "list softbreak",
        tests: [{
          shift: -2,
          type: "list_item_open"
        }, {
          shift: 0,
          type: "inline",
          children: [{
            position: -2,
            type: "softbreak"
          }, {
            position: -1,
            type: "text",
            content: utils.hasDelimiters("only", options)
          }]
        }],
        transform: (tokens, i, j) => {
          const token = tokens[i].children[j];
          const content = token.content;
          const attrs = utils.getAttrs(content, 0, options);
          let ii = i - 2;
          while (tokens[ii - 1] && tokens[ii - 1].type !== "ordered_list_open" && tokens[ii - 1].type !== "bullet_list_open") {
            ii--;
          }
          utils.addAttrs(attrs, tokens[ii - 1]);
          tokens[i].children = tokens[i].children.slice(0, -2);
        }
      }, {
        name: "list double softbreak",
        tests: [{
          shift: 0,
          type: str => str === "bullet_list_close" || str === "ordered_list_close"
        }, {
          shift: 1,
          type: "paragraph_open"
        }, {
          shift: 2,
          type: "inline",
          content: utils.hasDelimiters("only", options),
          children: arr => arr.length === 1
        }, {
          shift: 3,
          type: "paragraph_close"
        }],
        transform: (tokens, i) => {
          const token = tokens[i + 2];
          const content = token.content;
          const attrs = utils.getAttrs(content, 0, options);
          const openingToken = utils.getMatchingOpeningToken(tokens, i);
          utils.addAttrs(attrs, openingToken);
          tokens.splice(i + 1, 3);
        }
      }, {
        name: "list item end",
        tests: [{
          shift: -2,
          type: "list_item_open"
        }, {
          shift: 0,
          type: "inline",
          children: [{
            position: -1,
            type: "text",
            content: utils.hasDelimiters("end", options)
          }]
        }],
        transform: (tokens, i, j) => {
          const token = tokens[i].children[j];
          const content = token.content;
          const attrs = utils.getAttrs(content, content.lastIndexOf(options.leftDelimiter), options);
          utils.addAttrs(attrs, tokens[i - 2]);
          const trimmed = content.slice(0, content.lastIndexOf(options.leftDelimiter));
          token.content = last(trimmed) !== " " ? trimmed : trimmed.slice(0, -1);
        }
      }, {
        name: "\n{.a} softbreak then curly in start",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            position: -2,
            type: "softbreak"
          }, {
            position: -1,
            type: "text",
            content: utils.hasDelimiters("only", options)
          }]
        }],
        transform: (tokens, i, j) => {
          const token = tokens[i].children[j];
          const attrs = utils.getAttrs(token.content, 0, options);
          let ii = i + 1;
          while (tokens[ii + 1] && tokens[ii + 1].nesting === -1) {
            ii++;
          }
          const openingToken = utils.getMatchingOpeningToken(tokens, ii);
          utils.addAttrs(attrs, openingToken);
          tokens[i].children = tokens[i].children.slice(0, -2);
        }
      }, {
        name: "horizontal rule",
        tests: [{
          shift: 0,
          type: "paragraph_open"
        }, {
          shift: 1,
          type: "inline",
          children: arr => arr.length === 1,
          content: str => str.match(__hr) !== null
        }, {
          shift: 2,
          type: "paragraph_close"
        }],
        transform: (tokens, i) => {
          const token = tokens[i];
          token.type = "hr";
          token.tag = "hr";
          token.nesting = 0;
          const content = tokens[i + 1].content;
          const start = content.lastIndexOf(options.leftDelimiter);
          const attrs = utils.getAttrs(content, start, options);
          utils.addAttrs(attrs, token);
          token.markup = content;
          tokens.splice(i + 1, 2);
        }
      }, {
        name: "end of block",
        tests: [{
          shift: 0,
          type: "inline",
          children: [{
            position: -1,
            content: utils.hasDelimiters("end", options),
            type: t => t !== "code_inline" && t !== "math_inline"
          }]
        }],
        transform: (tokens, i, j) => {
          const token = tokens[i].children[j];
          const content = token.content;
          const attrs = utils.getAttrs(content, content.lastIndexOf(options.leftDelimiter), options);
          let ii = i + 1;
          while (tokens[ii + 1] && tokens[ii + 1].nesting === -1) {
            ii++;
          }
          const openingToken = utils.getMatchingOpeningToken(tokens, ii);
          utils.addAttrs(attrs, openingToken);
          const trimmed = content.slice(0, content.lastIndexOf(options.leftDelimiter));
          token.content = last(trimmed) !== " " ? trimmed : trimmed.slice(0, -1);
        }
      }];
    };
    function last(arr) {
      return arr.slice(-1)[0];
    }
  }
});

// ../markdown-it-attrs/index.js
var require_markdown_it_attrs = __commonJS({
  "../markdown-it-attrs/index.js"(exports, module) {
    "use strict";

    var patternsConfig = require_patterns();
    var defaultOptions = {
      leftDelimiter: "{",
      rightDelimiter: "}",
      allowedAttributes: []
    };
    module.exports = function attributes(md, options_) {
      let options = Object.assign({}, defaultOptions);
      options = Object.assign(options, options_);
      const patterns = patternsConfig(options);
      function curlyAttrs(state) {
        const tokens = state.tokens;
        for (let i = 0; i < tokens.length; i++) {
          for (let p = 0; p < patterns.length; p++) {
            const pattern = patterns[p];
            let j = null;
            const match = pattern.tests.every(t => {
              const res = test(tokens, i, t);
              if (res.j !== null) {
                j = res.j;
              }
              return res.match;
            });
            if (match) {
              pattern.transform(tokens, i, j);
              if (pattern.name === "inline attributes" || pattern.name === "inline nesting 0") {
                p--;
              }
            }
          }
        }
      }
      md.core.ruler.before("linkify", "curly_attributes", curlyAttrs);
    };
    function test(tokens, i, t) {
      const res = {
        match: false,
        j: null
      };
      const ii = t.shift !== void 0 ? i + t.shift : t.position;
      if (t.shift !== void 0 && ii < 0) {
        return res;
      }
      const token = get(tokens, ii);
      if (token === void 0) {
        return res;
      }
      for (const key of Object.keys(t)) {
        if (key === "shift" || key === "position") {
          continue;
        }
        if (token[key] === void 0) {
          return res;
        }
        if (key === "children" && isArrayOfObjects(t.children)) {
          if (token.children.length === 0) {
            return res;
          }
          let match;
          const childTests = t.children;
          const children = token.children;
          if (childTests.every(tt => tt.position !== void 0)) {
            match = childTests.every(tt => test(children, tt.position, tt).match);
            if (match) {
              const j = last(childTests).position;
              res.j = j >= 0 ? j : children.length + j;
            }
          } else {
            for (let j = 0; j < children.length; j++) {
              match = childTests.every(tt => test(children, j, tt).match);
              if (match) {
                res.j = j;
                break;
              }
            }
          }
          if (match === false) {
            return res;
          }
          continue;
        }
        switch (typeof t[key]) {
          case "boolean":
          case "number":
          case "string":
            if (token[key] !== t[key]) {
              return res;
            }
            break;
          case "function":
            if (!t[key](token[key])) {
              return res;
            }
            break;
          case "object":
            if (isArrayOfFunctions(t[key])) {
              const r = t[key].every(tt => tt(token[key]));
              if (r === false) {
                return res;
              }
              break;
            }
          default:
            throw new Error(`Unknown type of pattern test (key: ${key}). Test should be of type boolean, number, string, function or array of functions.`);
        }
      }
      res.match = true;
      return res;
    }
    function isArrayOfObjects(arr) {
      return Array.isArray(arr) && arr.length && arr.every(i => typeof i === "object");
    }
    function isArrayOfFunctions(arr) {
      return Array.isArray(arr) && arr.length && arr.every(i => typeof i === "function");
    }
    function get(arr, n) {
      return n >= 0 ? arr[n] : arr[arr.length + n];
    }
    function last(arr) {
      return arr.slice(-1)[0] || {};
    }
  }
});

// src/index.mjs
var import_markdown_it_attrs = __toESM(require_markdown_it_attrs(), 1);
var src_default = import_markdown_it_attrs.default;
export { src_default as default };
